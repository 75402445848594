import "./App.css";
import BigButton from "./compontents/button";

function App() {
  return (
    <>
      <div className="bg-[url('imgs/background.webp')] backdrop-blur w-screen h-screen ">
        <div className="w-full h-full backdrop-blur-sm grid place-items-center">
          <div className="text-center">
            <h1 className="md:text-9xl text-4xl font-extrabold text-white">
              OshrEQ
            </h1>
            <p className="text-gray-50 text-xl md:text-3xl m-5">
              Your Homework Proofmate
            </p>
            <div className="w-full h-full grid place-items-center">
              <BigButton
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&pp=ygUjcmljayBhc3RsZXkgbmV2ZXIgZ29ubmEgZ2l2ZSB5b3UgdXA%3D"
                text="Try it now!"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-24 bg-gradient-to-tr from-white to-gray-500 ">
        <h1 className="text-center font-extrabold text-3xl md:text-6xl m-5 mb-20">
          Our Partners
        </h1>
        <div className="grid  md:grid-cols-3 content-center gap-5">
          <a href="https://fau.eu" className="grid place-items-center ">
            <img
              src={require("./imgs/fau.png")}
              alt="FAU Erlangen-Nuernberg logo"
              className="md:h-16"
            ></img>
          </a>
          <a href="https://tum.de" className="grid place-items-center">
            <img
              src={require("./imgs/tum-logo.png")}
              alt="TU Muenchen logo"
              className="h-10 md:h-16"
            ></img>
          </a>
          <a
            href="https://www.uni-stuttgart.de/"
            className="grid place-items-center"
          >
            <img
              src={require("./imgs/uni-stuttgart.png")}
              alt="Uni Stuttgart Logo"
              className="md:h-16"
            ></img>
          </a>
        </div>
      </div>
      <div className="bg-black h-full p-8 md:p-24">
        <h1 className="text-5xl font-extrabold text-white my-6">
          The Proof Assistant of the{" "}
          <span className="text-blue-400">FUTURE</span>
        </h1>
        <div className="grid grid-cols-1 xl:grid-cols-4 md:gap-5 border border-white rounded ">
          <Card title="Fast." body="Written in blazingly fast OCaml!"></Card>
          <Card title="Ai." body="Using modern reasoning techniques!"></Card>
          <Card
            title="Security."
            body="Make sure that your code works correctly!"
          ></Card>
          <Card
            title="Research."
            body="Bringing Innovation to Software Verification!"
          ></Card>
        </div>
        <div>
          <h2></h2>
        </div>
      </div>
      <div className="bg-[url('imgs/sarntal.png')]  text-center bg-cover backdrop-blur w-screen h-screen ">
        <div className="w-screen h-screen backdrop-brightness-50 grid place-content-center ">
          {" "}
          <h2 className="text-white text-3xl md:text-9xl font-bold ">
            Made in <span className="text-blue-400">Sarntal</span>
          </h2>
          <h2
            className="relative xl:w-[max-content] text-white text-2xl md:text-6xl font-normal
before:absolute before:inset-0 before:bg-black
before:animate-typewriter"
          >
            As part of the{" "}
            <span className="text-blue-400">FerienAkademie 2024</span>
          </h2>
        </div>
      </div>
    </>
  );
}

function Card(props) {
  return (
    <div className="m-5 md:m-10">
      <h2 className="text-orange-400 text-2xl md:text-4xl font-bold ">
        {props.title}
      </h2>
      <p className="text-white my-3 font-mono">{props.body}</p>
    </div>
  );
}

export default App;
