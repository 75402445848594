import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Navbar from "./compontents/navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./About";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/learn-more" element={<About />} />{" "}
          <Route path="/about-us" element={<About />} />
        </Routes>
      </Navbar>
    </Router>
  </React.StrictMode>,
);
