function About() {
  return (
    <div>
      <div className="p-16">
        <h1 className="py-8 text-3xl w-full text-center">About OshrEQ</h1>
        <p className="text-xl">
          We are a group of students that formed during the{" "}
          <a className="text-blue-600" href="https://ferienakademie.de">
            Ferienakademie
          </a>
          , a summer school from the TU Munich, FAU Erlangen and Uni Stuttgart,
          that takes place in Sarntal. OshrEQ was Course 1 in the summer of
          2024.
        </p>
      </div>
    </div>
  );
}

export default About;
