import React, { useState } from "react";

function Navbar({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {" "}
      <nav className="grid grid-cols-2 md:grid-cols-4 p-4 bg-white items-center">
        <div className="md:col-span-3">
          <a href="/" className="font-extrabold text-2xl text-orange-400">
            OshrEQ
          </a>
        </div>
        <div className="flex flex-row-reverse">
          {" "}
          <button
            onClick={toggleDrawer}
            className="bg-orange-400 text-white px-4 py-2 rounded-lg 
                hover:bg-orange-500 transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>{" "}
        </div>
      </nav>
      {children}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-white shadow-lg
                transition-transform transform ${
                  isOpen ? "translate-x-0" : "-translate-x-full"
                }`}
      >
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-4">OshrEQ ein Menu</h2>
          <ul className="space-y-4">
            <li>
              <a href="/about-us" className="text-gray-800 hover:text-blue-500">
                About us
              </a>
            </li>
            <li>
              <a
                href="/learn-more"
                className="text-gray-800 hover:text-blue-500"
              >
                Learn more!
              </a>
            </li>
            <li>
              <a href="/project" className="text-gray-800 hover:text-blue-500">
                To the project!
              </a>
            </li>
          </ul>
        </div>
        <button
          onClick={toggleDrawer}
          className="absolute top-4 right-4 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </>
  );
}

export default Navbar;
