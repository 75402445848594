function BigButton(Props) {
  return (
    <a
      href={Props.href}
      className="text-xl md:text-3xl flex  border rounded-3xl px-7 font-bold text-gray-700 py-4 bg-blue-400 hover:bg-blue-300 border-blue-400 hover:border-blue-300 border-b-4 "
    >
      {Props.text}
    </a>
  );
}

export default BigButton;
